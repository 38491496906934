<template>
  <div class="charging-point-type-info pa-10">
    <headline>
      {{ $t('charging-point-type.charging-point-type') }}
    </headline>

    <div class="grid">


      <v-row>
        <v-col class="label" sm="3">
          {{ $t('model') }} :
        </v-col>
        <v-col sm="3">
          {{ chargingPointType.model }}
          <v-btn class="ml-5" small :to="{name: 'charging-point-types.edit', params: {id: chargingPointType.id}}">
            <v-icon left>mdi-cog</v-icon>
            {{ $t('edit')}}
          </v-btn>
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('vendor') }} :
        </v-col>
        <v-col sm="3">
          {{ chargingPointType.vendor }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" sm="3">
          {{ $t('model-label') }} :
        </v-col>
        <v-col sm="3">
          {{ chargingPointType.modelLabel }}
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('vendor-label') }} :
        </v-col>
        <v-col sm="3">
          {{ chargingPointType.vendorLabel }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="label" sm="3">
          {{ $t('charging-point-type.max-power') }} :
        </v-col>
        <v-col sm="3">
          {{ chargingPointType.maxPower}}
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('charging-point-type.dynamic-load-management-support') }} :
        </v-col>
        <v-col sm="3">
          <div class="switch">
            <v-switch
                readonly
                color="#00897E"
                inset
                hide-details
                v-model="chargingPointType.dynamicLoadManagementSupport"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="label" sm="3">
          {{ $t('charging-point-type.charging-profile-support') }} :
        </v-col>
        <v-col sm="3">
          <div class="switch">
            <v-switch
                readonly
                color="#00897E"
                inset
                hide-details
                v-model="chargingPointType.chargingProfileSupport"
            />
          </div>
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('charging-point-type.status-notification-slave') }} :
        </v-col>
        <v-col sm="3">
          <div class="switch">
            <v-switch
                readonly
                color="#00897E"
                inset
                hide-details
                v-model="chargingPointType.statusNotificationSlave"
            />
          </div>
        </v-col>
      </v-row>

      <v-row justify="center" class="evses" v-if="!chargingPointType.evses">
        <v-col class="text-center">
          {{ $t('charging-point.no-eveses') }}
        </v-col>
      </v-row>
      <v-row justify="center" class="evses" v-else>
        <v-col cols="12" md="6" v-for="evse in chargingPointType.evses" :key="evse.id">
          <div class="evse">
            <headline dotted>
              {{ $t('charging-point.charging-point') }} {{ evse.num }}
            </headline>

            <v-row  class="connectors">
              <v-col cols="12">
                <div  class="connector">
                  <div class="grid dense small">
                    <v-row class="headline-title">
                      <v-col class="label">
                        {{ $t('charging-point.connector') }}
                      </v-col>
                      <v-col v-for="(connector, i) in evse.connectors" :key="'connectorId-' + i">
                        {{ connector.connectorId }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="label">
                        {{ $t('charging-point.connector-type') }} :
                      </v-col>
                      <v-col v-for="(connector, i) in evse.connectors" :key="'typeName-' + i">

                        <v-tooltip
                            bottom
                            color="primary"
                        >
                          <template v-slot:activator="{ on, attrs }">
                              <span
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                {{ $t('enums.connector-type.' + connector.type) }}
                              </span>
                          </template>
                          <span>{{ connector.type }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="label">
                        {{ $t('charging-point.format') }} :
                      </v-col>
                      <v-col v-for="(connector, i) in evse.connectors" :key="'format-' + i">
                        {{ $t('enums.connector-format.' + connector.format) }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="label">
                        {{ $t('charging-point.power-type') }} :
                      </v-col>
                      <v-col v-for="(connector, i) in evse.connectors" :key="'powerType-' + i">
                        {{ $t('enums.power-type.' + connector.powerType) }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="label">
                        {{ $t('charging-point.voltage') }} :
                      </v-col>
                      <v-col v-for="(connector, i) in evse.connectors" :key="'voltage-' + i">
                        {{ connector.voltage }}V
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="label">
                        {{ $t('charging-point.power') }} :
                      </v-col>
                      <v-col v-for="(connector, i) in evse.connectors" :key="'power-' + i">
                        {{ connector.power }}kW
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="label">
                        {{ $t('charging-point.amperage') }} :
                      </v-col>
                      <v-col v-for="(connector, i) in evse.connectors" :key="'amperage-' + i">
                        {{ connector.amperage }}A
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="label">
                        {{ $t('charging-point.charging-mode') }} :
                      </v-col>
                      <v-col v-for="(connector, i) in evse.connectors" :key="'chargingMode-' + i">
                        {{ $t('enums.charging-mode.' + connector.chargingMode) }}
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>

      </v-row>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    chargingPointType() {
      return this.$parent.chargingPointType ?? null
    },


  },

  methods: {


  }
}
</script>

<style lang="scss">
.charging-point-type-info {
  .evses {
    .row {
      margin: -4px;
      &  + .row {
        margin-top: 4px;
      }
      & > .col, & > [class*=col-] {
        padding: 4px;
        &:not(.label) {
          justify-content: center;
        }
      }
    }
    .v-expansion-panel {
      background: white;
    }
    .evse {
      //border: 1px dotted #ccc;
      border-radius: 10px;
      padding: 0 20px;
      .sub-headline {
        margin: 20px 0;
        font-weight: 500;
      }
      .physical-reference {
        border: 2px solid var(--v-primary-base);
        color: var(--v-primary-base);
        border-radius: 15px;
        padding: 0px 10px;
        font-size: 16px;
        line-height: 22px;
        height: 26px;
        font-weight: bold;
        box-shadow: 0 0 15px #e53c225e;

      }
      .connectors {
        .connector {
          margin: 0 30px;
          border-radius: 10px;
          .grid {
            .headline-title {
              .col {
                color: var(--v-primary-base);
                font-weight: 600;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

</style>
