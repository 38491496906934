<template>
  <v-dialog v-model="dialog" scrollable max-width="1000">
    <v-form ref="form" @submit.prevent="save">
      <v-card min-height="300">
        <v-card-title>
          {{ $t("charging-point.configurations") }}
        </v-card-title>

        <v-divider />

        <v-card-text>

          <v-alert
              v-if="error"
              type="error"
              outlined
              text
          >
            Timeout...
            <a href="#" @click.prevent="loadData">retry ?</a>
          </v-alert>

          <div v-else-if="configuration.length === 0">
            <v-row>
              <v-col class="text-center mt-6">
                <v-progress-circular indeterminate color="primary" /> {{ $t('loading') }}...
              </v-col>
            </v-row>
          </div>

          <v-data-table v-else
            :headers="headers"
            :items="configuration"
            :items-per-page="9999"
            hide-default-footer
          >
            <template #item.key="{item}">
              <i v-if="item.readonly" :class="{changed: oldConfiguration[item.key] !== item.value}">{{ item.key }}</i>
              <b v-else :class="{changed: oldConfiguration[item.key] !== item.value}">{{ item.key }}</b>
            </template>
            <template #item.value="{item}">
              {{ item.type }}
              <v-text-field v-if="item.readonly" v-model="item.value" disabled />
              <v-switch v-else-if="false && item.type === 'boolean'" v-model="item.value" />
              <v-text-field v-else-if="item.type === 'number'" v-model="item.value" type="number" />
              <v-text-field v-else v-model="item.value"/>
            </template>
          </v-data-table>

        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-row class="ma-0">
            <v-col class="text-right">
              <v-btn color="secondary" @click="close" type="button">
                {{ $t("close") }}
              </v-btn>
              <v-btn color="primary" type="submit" class="ml-3"  v-if="!error && configuration.length > 0">
                {{ $t("save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import CommandRepository from "@repository/CommandRepository";
import {EventBus} from "@includes/EventBus";

export default {
  data() {
    return {
      dialog: false,
      error: false,
      configuration: [],
      oldConfiguration : {},
      headers: [
        {text: this.$t('charging-point.configuration-key'), value: "key", width: "40%"},
        {text: this.$t('charging-point.configuration-value'), value: "value"},
      ],
      ocppProperties: {
        AllowOfflineTxForUnknownId: { type: "boolean" },
        AuthorizationCacheEnabled: { type: "boolean" },
        AuthorizeRemoteTxRequests: { type: "boolean" },
        BlinkRepeat: { type: "number" },
        ClockAlignedDataInterval: { type: "number" },
        ConnectionTimeOut: { type: "number" },
        GetConfigurationMaxKeys: { type: "number" },
        HeartbeatInterval: { type: "number" },
        LightIntensity: { type: "number" },
        LocalAuthorizeOffline: { type: "boolean" },
        LocalPreAuthorize: { type: "boolean" },
        MaxEnergyOnInvalidId: { type: "number" },
        MeterValuesAlignedData: { type: "string" },
        MeterValuesAlignedDataMaxLength: { type: "number" },
        MeterValuesSampledData: { type: "string" },
        MeterValuesSampledDataMaxLength: { type: "number" },
        MeterValueSampleInterval: { type: "number" },
        MinimumStatusDuration: { type: "number" },
        numberOfConnectors: { type: "number" },
        ResetRetries: { type: "number" },
        ConnectorPhaseRotation: { type: "string" },
        ConnectorPhaseRotationMaxLength: { type: "number" },
        StopTransactionOnEVSideDisconnect: { type: "boolean" },
        StopTransactionOnInvalidId: { type: "boolean" },
        StopTxnAlignedData: { type: "string" },
        StopTxnAlignedDataMaxLength: { type: "number" },
        StopTxnSampledData: { type: "string" },
        StopTxnSampledDataMaxLength: { type: "number" },
        SupportedFeatureProfiles: { type: "string" },
        SupportedFeatureProfilesMaxLength: { type: "number" },
        TransactionMessageAttempts: { type: "number" },
        TransactionMessageRetryInterval: { type: "number" },
        UnlockConnectorOnEVSideDisconnect: { type: "boolean" },
        WebSocketPingInterval: { type: "number" },
      }
    }
  },

  props: {
    chargingPoint: Object,
  },

  destroyed() {
    if(this.chargingPoint) {
      EventBus.$off('on-GetConfiguration-' + this.chargingPoint.id)
    }
  },

  methods: {
    open()  {
      this.dialog = true
      this.loadData()
    },

    close()  {
      this.dialog = false
      this.configuration = []
      EventBus.$off('on-GetConfiguration-' + this.chargingPoint.id)
    },

    loadData() {
      this.configuration = []
      this.error = false
      CommandRepository.getConfiguration(this.chargingPoint.id).then(response => {
        let timeout = setTimeout(() => {
          this.error = true
        }, 30000)
        EventBus.$on('on-GetConfiguration-' + this.chargingPoint.id, data => {
          clearTimeout(timeout)
          this.oldConfiguration = this.treatOldConfiguration(data.message.payload.configurationKey)
          this.configuration = this.treatConfiguration(data.message.payload.configurationKey)
          EventBus.$off('on-GetConfiguration-' + this.chargingPoint.id)
        })
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
        this.close()
      })
    },

    save() {

      let changedConfiguration = []
      this.configuration.forEach(item => {
        if(this.oldConfiguration[item.key] !== item.value) {
          changedConfiguration.push(item)
        }
      })

      if(changedConfiguration.length > 0) {
        this.$dialog.confirm({text: this.$t("charging-point.change-configuration-confirmation")}).then(result => {
          if(result) {
            CommandRepository.changeConfigurations(this.chargingPoint.id, changedConfiguration).then(response => {
              this.close()
            }).catch(err => {
              this.$dialog.notify.error(err.response?.data?.message || err.message)
            })
          }
        })
      } else {
        this.$dialog.notify.warning("No change")
      }
    },

    treatOldConfiguration(data) {
      let newData = {}
      data.forEach(item => {
        newData[item.key] = item.value
      })
      return newData
    },

    treatConfiguration(data) {
      let newData = this.cloneObj(data)
      newData.forEach(item => {
        if(this.ocppProperties[item.key]) {
          item.type = this.ocppProperties[item.key].type
        } else if(typeof item.value === "boolean") {
          item.type = 'boolean'
        } else if(typeof item.value === "number") {
          item.type = 'number'
        } else {
          item.type = 'string'
        }
      })
      return newData
    }
  },
}
</script>

<style lang="scss">
  .changed {
    color: red;
  }
</style>
